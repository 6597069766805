@import './variables.scss';
// Typography
// Font face

@each $font-face in Ubuntu-Regular, Ubuntu-Medium {
  @font-face {
    font-family: '#{$font-face}';
    src: url('../fonts/#{$font-face}.ttf') format('truetype');
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-black900;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
  a {
    font-weight: inherit;
  }
}

h1 {
  color: $color-link-primary;
  font-weight: 600;
}

h2 {
  line-height: 1.2;
  font-weight: 600;
}

p {
  line-height: 1.5;
  margin: 0;
  img {
    margin: 0;
  }
}

em {
  font-style: italic;
}

strong {
  font-weight: bold;
}

hr {
  border: solid $blackish;
  border-width: 1px 0 0;
  clear: both;
  height: 0;
  margin: 10px 0 30px;
}

// __Links__
a {
  cursor: pointer;
  transition: color .1s;
}
a,
a:visited {
  color: $color-link-primary;
  text-decoration: none;

  &:hover {
    color: $color-black600;
    transition: color 0.3s linear;
  }
}

a.secondary,
a.secondary:visited {
  color: $color-black600;
  text-decoration: none;

  &:hover {
    color: $color-link-primary;
    transition: color 0.3s linear;
  }
}
