@import "./../../../styles/variables.scss";
@import "../../../styles/dependencies/helpers.scss";

nav.container {
  width: 100vw;
  display: flex;
  background: $primary2;
  justify-content: center;
  align-items: center;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    max-width: 260px;
    height: $height-header-pc;
    color: $white;
    &.active,
    &:hover {
      color: $primary;
    }
    .icon_header {
      font-size: 24px;
    }
    p {
      font-size: 16px;
      margin: 0 0 0 16px;
    }
  }
}

@media (max-width: 768px) {
  nav.container {
    border-radius: 20px;
    width: auto;
    justify-content: space-around;
    padding: 0 8px;
    margin: 0 0 10px;
    a {
      width: auto;
      height: calc($height-header-mobile - 10px);
      .icon_header {
        font-size: 25px;
      }
      p {
        display: none;
      }
      &.scanItem {
        padding: 14px;
        height: auto;
        border-radius: 50%;
        background-color: $primary;
        .icon_header {
          font-size: 28px;
        }
        &.active,
        &:hover {
          color: $white;
        }
      }
    }
  }
}
