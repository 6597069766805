//************************
//** COLORS
//************************

$primary: #25a28c;
$primary1: #208b78;
$primary2: #161a34;
$primary-75: #5cbaa9;
$primary-50: #92d1c5;
$primary-25: #c9e8e2;
$primary-10: #e8f8f4;
$primary-5: #f7fffd;

$secondary: #a7cb23;
$secondary-75: #bdd65a;
$secondary-50: #d3e391;
$secondary-25: #e9f1c8;
$secondary-100: green;


$tertiary: #c6c6d4;

$yellow: #ffee00;
$yellow-0: #9c9200;

$red: #ff6363;
$red-0: red;
$red-25: #ffa5a5;
$red-10: #ffe7e7;

$grey-02: #f9f9fb;
$grey-05: #f1f1f4;
$grey-10: #e2e3e9;
$grey-20: #c6c6d4;
$grey-30: #a9aabc;
$grey-40: #8d8ea5;
$grey-50: #70728f;
$grey-60: #5a5b72;
$grey-70: #434456;
$grey-80: #2d2d39;
$grey-90: #16171d;

// Color aliases for retrocompatibility
$black: #000;
$blackish: $grey-90;
$grayer: $grey-70;
$gray: $grey-60;
$gray-light: $grey-50;
$gray-lighter: $grey-30;
$whitish: $grey-10;
$mass-white: $grey-05;
$white: #fff;
$white-line: rgba(255, 255, 255, 0.37);


// Primary colors
$primary-light: $primary-75;
$primary-lighter: $primary-10;
$primary-dark: $primary-50;
$primary-background: $primary-25;

// Secondary colors
$secondary-light: $secondary-50;
$secondary-dark: #91b011;

// Primary colors
$button-primary-inteam: linear-gradient(45deg, #37c36b 14.64%, #00a5dc 85.36%);
$color-solid-primary: #83eede;
$color-link-primary: #008aa8;
$color-link-tertiary: #70728f;

// Grayscale
$color-gray100: #f9f9fb;
$color-gray200: #eceff4;
$color-gray300: #e5e9f0;
$color-gray400: #d8dee9;
$color-gray500: #c9ceda;
$color-gray800: #505c74;

$color-black600: #4c566a;
$color-black700: #434c5e;
$color-black800: #3b4252;
$color-black900: #2e3440;

// Violet
$color-violet100: #f2eff8;

$color-white: #ffffff;
$bg-color: #f1f3fb;
$color-black: #000000;