$height-header-pc: 60px;
$height-header-mobile: 80px;
$width-wrapper-pc: 900px;

/**********************
**** VERTICAL HEIGHT
***********************/

$negative-layer: -1;
$first-layer: 2;
$second-layer: 3;
$third-layer: 4;
$fourth-layer: 5;
$layout-layer: 9; /* Do not use this except for the main layout */
$layout-floating-layer: 10;
$loading-layer: 97;
$lightbox-layer: 98;
$overall-layer: 99;
$top-layer: 100;
$best-layer: 9999;

@mixin ellipsis($line: 1) {
    overflow: hidden;
    @if $line == 1 {
        text-overflow: ellipsis;
        white-space: nowrap;
    } @else {
        line-clamp: $line;
        -webkit-line-clamp: $line;
        display: -webkit-box;
        display: -moz-box;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
    }
}
