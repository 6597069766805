// Basic layout styles
@import "dependencies/helpers.scss";
@import "./variables.scss";

* {
  box-sizing: border-box;
}

html {
  min-height: 100%;
  width: 100%;
}
body {
  line-height: 1.25;
  min-height: 100vh;
  overflow-x: hidden;
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.icon-component {
  align-items: center;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  div#root {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
  }
}
