@import "../../../styles/dependencies/helpers.scss";
@import "../../../styles/variables.scss";

.container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $best-layer;
    background-color: rgba($black, 0.2);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
