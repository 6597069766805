@import "./../../styles/variables.scss";
@import "../../styles/dependencies/helpers.scss";

.layout {
  padding-top: $height-header-pc;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .wrapper {
    min-height: calc(100vh - $height-header-pc);
    width: 100%;
    height: 100%;
    margin: 0 auto;
    max-width: $width-wrapper-pc;
    padding: 20px 20px 40px;
    overflow: hidden;
  }
  .header {
    position: fixed;
    z-index: $top-layer;
    top: 0;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .layout {
    padding-top: 0;
    padding-bottom: $height-header-mobile;
    .wrapper {
      min-height: calc(100vh - $height-header-mobile);
      max-width: 100%;
      padding: 24px 16px;
    }
    .header {
      background-color: $white;
      top: unset;
      bottom: 0;
      border-radius: 20px 20px 0 0;
      left: 10px;
      right: 10px;
      width: calc(100% - 10px - 10px);
    }
  }
}
