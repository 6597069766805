// Elements

// Blockquotes
blockquote,
blockquote p {
  line-height: 1.25rem;
}
blockquote {
  margin: 0 0 20px;
  padding: 0.5rem 1.25rem;
  cite {
    display: block;
    &::before {
      content: "\2014 \0020";
    }
  }
}

div.full,
section.full {
  width: 100%;
}

// __Lists__
ul {
  list-style: none outside;
}
ol {
  list-style: decimal;
}

sup {
  vertical-align: super;
}

img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

button {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

.not-clickable {
  cursor: default;
}

.draggable {
  cursor: move;
}
