@import "variables.scss";

:root {
    --swiper-theme-color: #25a28c !important;
    --swiper-pagination-bottom: -24px !important;
}

.swiper {
    overflow: visible;
}

.ant-pagination {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-top: 24px;
}

.ant-form {
    width: 100%;
    .ant-form-item {
        margin-bottom: 24px;
        width: 100%;
        .ant-form-item-row {
            .ant-form-item-label {
                max-width: 100%;
                padding: 0 0 8px 4px;
                label {
                    font-size: 14px;
                    font-weight: 600;
                }
            }
            .ant-form-item-control {
                .ant-form-item-control-input {
                    .ant-form-item-control-input-content {
                        > input,
                        .ant-input-affix-wrapper {
                            min-width: 420px;
                            font-size: 14px;
                            line-height: 24px;
                            border-radius: 10px;
                            padding: 6px 16px;
                        }
                    }
                }
            }
        }
        .ant-form-item-explain {
            padding: 4px 0 0 4px;
            font-size: 14px;
            .ant-form-item-explain-error {
                color: $red-0;
            }
        }
        .ant-form-item-margin-offset {
            display: none;
        }
    }
}

.ant-btn {
    min-height: 40px;
    min-width: 160px;
    font-size: 14px;
    height: auto;
    padding: 4px 24px;
    border-radius: 10px;
    box-shadow: none;
    font-weight: 500;
    &.ant-btn-dashed {
        border-color: $color-black700;
        &:not(:disabled):hover,
        &:not(:disabled):active {
            color: $yellow-0;
            border-color: $yellow-0;
        }
    }
}

.swiper {
    .swiper-wrapper {
        .swiper-slide {
            img {
                border-radius: 20px;
                height: 200px;
            }
        }
    }
}

.ant-modal {
    .ant-modal-content {
        padding: 16px 20px;
        border-radius: 20px;
        .ant-modal-close {
            color: $black;
            width: 50px;
            height: 50px;
            top: 0;
            right: 0;
            background-color: $white;
            border-radius: 0 20px 0 20px;
            .ant-modal-close-x {
                font-size: 24px;
                line-height: 24px;
            }
        }
    }
}

// Responsive
@media (max-width: 768px) {
    .ant-pagination {
        margin-top: 20px;
    }

    .ant-form {
        .ant-form-item {
            margin-bottom: 20px;
            .ant-form-item-row {
                .ant-form-item-label {
                    label {
                    }
                }
                .ant-form-item-control {
                    .ant-form-item-control-input {
                        .ant-form-item-control-input-content {
                            > input,
                            .ant-input-affix-wrapper {
                                min-width: auto;
                                padding: 6px 12px;
                            }
                        }
                    }
                }
            }
        }
    }

    .ant-btn {
        min-height: 36px;
        min-width: 120px;
        padding: 4px 20px;
    }

    .swiper {
        .swiper-wrapper {
            .swiper-slide {
                img {
                    border-radius: 10px;
                    height: 120px;
                }
            }
        }
    }

    .ant-modal {
        .ant-modal-content {
            padding: 12px 16px;
            border-radius: 10px;
            .ant-modal-close {
                width: 40px;
                height: 40px;
                border-radius: 0 10px 0 10px;
                .ant-modal-close-x {
                    font-size: 20px;
                    line-height: 20px;
                }
            }
        }
    }
}
